export const builderWizardTemplate = (ctx: any) => {
  return `<div class="formio builder row formbuilder">
            <div class="col-xs-4 col-sm-3 col-md-2 formcomponents">
              ${ctx.sidebar}
            </div>
            <div class="col-xs-8 col-sm-9 col-md-10 formarea">
              <div class="breadcrumb"></div>
              <div ref="form">
                ${ctx.form}
              </div>
            </div>
          </div>`;
};
