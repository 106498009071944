import { Components } from "formiojs";
import { paddingTemplate } from "@/formio-templates/paddingTemplate";
const FieldComponent = (Components as any).components.field;

export default class PaddingComponent extends (FieldComponent as any) {
  static schema(): any {
    return FieldComponent.schema({
      type: "padding",
      label: "Padding",
      input: false,
    });
  }

  static builderInfo = {
    title: "Padding",
    group: "layout",
    icon: "indent",
    weight: -5,
    schema: PaddingComponent.schema(),
  };

  get labelInfo() {
    return {
      hidden: true,
    };
  }

  createLabel() {
    // No label needed for pudding.
  }

  render() {
    return super.render(paddingTemplate(this));
  }
}
