export const builderSidebarTemplate = (ctx: any) => {
  return `<div id="${ctx.groupId}" class="accordion builder-sidebar ${
    ctx.scrollEnabled ? " builder-sidebar_scroll" : ""
  }" ref="sidebar">
            ${(function () {
              let result = "";
              ctx.groups.forEach(function (group: any) {
                result += `${group}`;
              });

              return result;
            })()}
          </div>`;
};
