const inputTextTypes = [
  "textfield",
  "email",
  "phone",
  "textarea",
  "number",
  "select",
  "url",
  "day",
  "datetime",
];
export const getAliasStyledType = (type: string) => {
  if (inputTextTypes.includes(type)) {
    return "textfield";
  }
  return type;
};
