import {
  findCssValue,
  addShadowDomStyle,
  isCurrentForm,
} from "@/features/utils";
import tinycolor from "tinycolor2";
import { getAliasStyledType } from "@/features/builderHelper";

const labelAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const labelElement = element?.querySelector("label");
  if (labelElement && schema?.designOptions) {
    labelElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.labelStyle;
  }
};

const inputAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const attachInput = () => {
    const compElement = element.querySelector(
      `${
        component.type === "textarea" ? "textarea" : "input"
      }:not(.flatpickr-input)`
    );
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  };
  // on datetime type input add in DOM later
  component.type === "datetime" ? setTimeout(attachInput, 500) : attachInput();
};

const selectAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  let compElement;
  // looks like dropdown element added later
  setTimeout(() => {
    compElement = element?.querySelector(".selection.dropdown");
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  }, 0);
};

const datetimeAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  let compElement;
  let iconElement;
  // looks like dropdown element added later
  setTimeout(() => {
    compElement = element?.querySelector(".form-control.input");
    iconElement = element?.querySelector(".input-group-text");
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
    if (iconElement && schema?.designOptions) {
      (iconElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  }, 0);
};

const radioAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const outerBoxes = element?.querySelectorAll(".custom-outer-box");
  const radioLabels = element?.querySelectorAll(".custom-radio-label span");
  const radioRowLabels = element?.querySelectorAll(".custom-control");
  if (outerBoxes && schema?.designOptions) {
    outerBoxes.forEach((item) => {
      const outerBoxStyle =
        schema.designOptions[getAliasStyledType(component.type)]
          ?.radioOuterBoxStyle;
      (item as HTMLElement).style.cssText = outerBoxStyle;
      (item as HTMLElement).style.height = findCssValue(
        "width",
        outerBoxStyle || "16px"
      );
      const innerBox = item.firstElementChild as HTMLElement;
      innerBox.style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.radioSelectedColor;
      innerBox.style.top = innerBox.style.left = `-${findCssValue(
        "border-width",
        outerBoxStyle || "-1px"
      )}`;
    });
  }
  if (radioLabels && schema?.designOptions) {
    radioLabels.forEach((item) => {
      (item as HTMLElement).style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.checkboxLabelStyle;
    });
  }
  if (radioRowLabels && schema?.designOptions) {
    radioRowLabels.forEach((item) => {
      (item as HTMLElement).style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.checkboxRowLabelStyle;
    });
  }
};

const placeholderAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  if (schema?.designOptions?.placeholderColor) {
    const shadowRootsCount = document.querySelectorAll("qntn-form").length;
    const color = findCssValue("color", schema.designOptions.placeholderColor);

    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .form-control::placeholder",
        `color: ${color};`
      );
    } else {
      const rootVariables = document.querySelector(":root") as HTMLElement;
      rootVariables.style.setProperty("--input-placeholder-color", color);
    }
  }
};

const checkboxHoverAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const rootVariables = document.querySelector(":root") as HTMLElement;
  const shadowRootsCount = document.querySelectorAll("qntn-form").length;

  if (schema?.designOptions?.checkboxHoverColor) {
    const color = findCssValue(
      "color",
      schema.designOptions.checkboxHoverColor
    );
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .custom-control.custom-checkbox:hover .custom-inner-box",
        `background-color: ${color} !important;`
      );
    } else {
      rootVariables.style.setProperty("--checkbox-hover-color", color);
    }
  }

  if (schema?.designOptions?.radioHoverColor) {
    const color = findCssValue("color", schema.designOptions.radioHoverColor);
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .custom-control.custom-radio:hover .custom-inner-box",
        `background-color: ${color} !important;`
      );
    } else {
      rootVariables.style.setProperty("--radio-hover-color", color);
    }
  }
};

const descriptionAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const descriptionElement = element?.querySelector(
    ".form-text.text-muted"
  ) as HTMLElement;
  if (descriptionElement && schema?.designOptions) {
    descriptionElement.style.cssText =
      schema.designOptions[
        getAliasStyledType(component.type)
      ]?.descriptionStyle;
  }
};

const dayLabelAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const labelElements = element?.querySelectorAll("label");
  if (labelElements && schema?.designOptions) {
    labelElements.forEach((item: HTMLElement) => {
      item.style.cssText = schema.designOptions["day"]?.labelStyle;
    });
  }
};

const facebookButtonAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  if (
    schema?.designOptions &&
    schema.designOptions[getAliasStyledType(component.type)]
      ?.facebookButtonStyle
  ) {
    const rootVariables = document.querySelector(":root") as HTMLElement;
    const backgroundColor = findCssValue(
      "background-color",
      schema.designOptions[getAliasStyledType(component.type)]
        ?.facebookButtonStyle
    );
    const shadowRootsCount = document.querySelectorAll("qntn-form").length;

    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .fb-btn",
        `background-color: ${backgroundColor};
                border-color: ${tinycolor(backgroundColor)
                  .darken(6)
                  .toHexString()}
                ${tinycolor(backgroundColor).darken(9).toHexString()}
                ${tinycolor(backgroundColor).darken(12).toHexString()};`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .fb-btn .fb-icon",
        `background-color: ${tinycolor(backgroundColor)
          .darken(15)
          .toHexString()};`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .fb-btn:hover",
        `background-color: ${tinycolor(backgroundColor)
          .darken(7)
          .toHexString()};`
      );
    } else {
      rootVariables.style.setProperty(
        "--facebook-button-background",
        backgroundColor
      );
      rootVariables.style.setProperty(
        "--facebook-button-icon-background",
        tinycolor(backgroundColor).darken(15).toHexString()
      );
      rootVariables.style.setProperty(
        "--facebook-button-hovered-background",
        tinycolor(backgroundColor).darken(7).toHexString()
      );
      rootVariables.style.setProperty(
        "--facebook-button-border-color-top",
        tinycolor(backgroundColor).darken(6).toHexString()
      );
      rootVariables.style.setProperty(
        "--facebook-button-border-color-bottom",
        tinycolor(backgroundColor).darken(9).toHexString()
      );
      rootVariables.style.setProperty(
        "--facebook-button-border-color-side",
        tinycolor(backgroundColor).darken(12).toHexString()
      );
    }
  }
};

const paddingAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const paddingElement = element?.querySelector(".padding-component");
  if (paddingElement) {
    (paddingElement as HTMLElement).style.cssText = component.schema.height;
  }
};

const buttonAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const buttonElement = element?.querySelector("button");
  if (buttonElement && schema?.designOptions) {
    buttonElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.buttonStyle;
  }
};

const buttonAlignAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const container = element?.querySelector(".formio-component-button");
  const containerElement = container ? (container as HTMLElement) : element;
  if (containerElement && schema?.designOptions) {
    containerElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.buttonAlign;
  }
};

export const buttonHoverAttach = (
  component: any,
  element: HTMLElement | null,
  schema: any,
  formId = ""
) => {
  if (formId && element && !isCurrentForm(formId, element)) {
    return;
  }

  const rootVariables = document.querySelector(":root") as HTMLElement;
  const shadowRootsCount = document.querySelectorAll("qntn-form").length;

  if (schema?.designOptions?.buttonHoverColor) {
    const color = findCssValue("color", schema.designOptions.buttonHoverColor);
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .formio-wizard-nav-container .btn-primary:hover",
        `background-color: ${color} !important;`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component.formio-component-button .btn-primary:hover",
        `background-color: ${color} !important;`
      );
    } else {
      rootVariables.style.setProperty("--button-hover-color", color);
    }
  }

  if (schema?.designOptions?.buttonBorderHoverColor) {
    const color = findCssValue(
      "color",
      schema.designOptions.buttonBorderHoverColor
    );
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .formio-wizard-nav-container .btn-primary:hover",
        `border-color: ${color} !important;`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component.formio-component-button .btn-primary:hover",
        `border-color: ${color} !important;`
      );
    } else {
      rootVariables.style.setProperty("--button-border-hover-color", color);
    }
  }

  if (schema?.designOptions?.buttonFontHoverColor) {
    const color = findCssValue(
      "color",
      schema.designOptions.buttonFontHoverColor
    );
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .formio-wizard-nav-container .btn-primary:hover",
        `color: ${color} !important;`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component.formio-component-button .btn-primary:hover",
        `color: ${color} !important;`
      );
    } else {
      rootVariables.style.setProperty("--button-font-hover-color", color);
    }
  }
};

const confirmationLinkAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  if (
    schema?.designOptions &&
    schema.designOptions[getAliasStyledType(component.type)]?.confirmationStyle
  ) {
    const color = findCssValue(
      "color",
      schema.designOptions[getAliasStyledType(component.type)]
        ?.confirmationStyle
    );
    const shadowRootsCount = document.querySelectorAll("qntn-form").length;
    if (formId && shadowRootsCount > 1) {
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .confirmation-link",
        `color: ${color};`
      );
      addShadowDomStyle(
        formId,
        ".qntn-form .formio-component .confirmation-link:hover",
        `color: ${tinycolor(color).darken(15).toHexString()};`
      );
    } else {
      const rootVariables = document.querySelector(":root") as HTMLElement;
      rootVariables.style.setProperty("--confirmation-link-color", color);
      rootVariables.style.setProperty(
        "--confirmation-link-hover-color",
        tinycolor(color).darken(15).toHexString()
      );
    }
  }
};

const customTexttAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const textElement = element?.querySelector(".custom-text-component");
  if (textElement && schema?.designOptions) {
    (textElement as HTMLElement).style.cssText =
      schema.designOptions[component.type]?.customTextStyle;
  }
};

const componentAttach = (
  component: any,
  element: HTMLElement,
  schema: any,
  formId = ""
) => {
  if (formId && !isCurrentForm(formId, element)) {
    return;
  }

  const componentElement = element.classList.contains("formio-component")
    ? element
    : (element?.querySelector(".formio-component") as HTMLElement);
  if (componentElement && schema?.designOptions) {
    componentElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.componentStyle;
  }
};

export const componentsAttachList: Record<
  string,
  Array<
    (component: any, element: HTMLElement, schema: any, formId?: string) => void
  >
> = {
  input: [
    labelAttach,
    inputAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  url: [
    labelAttach,
    inputAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  checkbox: [
    labelAttach,
    radioAttach,
    descriptionAttach,
    checkboxHoverAttach,
    componentAttach,
  ],
  radio: [
    labelAttach,
    radioAttach,
    descriptionAttach,
    checkboxHoverAttach,
    componentAttach,
  ],
  confirmation: [
    radioAttach,
    checkboxHoverAttach,
    confirmationLinkAttach,
    componentAttach,
  ],
  select: [
    labelAttach,
    selectAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  day: [dayLabelAttach, descriptionAttach, componentAttach],
  datetime: [labelAttach, datetimeAttach, descriptionAttach, componentAttach],
  button: [buttonAttach, buttonAlignAttach, buttonHoverAttach],
  facebookButton: [facebookButtonAttach],
  padding: [paddingAttach],
  customText: [customTexttAttach],
  customText2: [customTexttAttach],
  customText3: [customTexttAttach],
};
