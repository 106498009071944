export const getFormioTranslations = (lang: string) => {
  return fetch(
    `${
      window?.Drupal?.settings?.qn_forms?.languages_base_url
        ? window.Drupal.settings.qn_forms.languages_base_url
        : `${process.env.VUE_APP_AWS_URL}/languages`
    }/form/${lang}.json`
  ).then((res) => {
    return res.json();
  });
};

export const getLangCode = (langCode: string) => {
  const languageParts = langCode.split("-");
  return languageParts[0];
};

export const setFormioI18nSetting = async (
  lang: string,
  settingsArray: any[]
) => {
  try {
    const formioTranslate = await getFormioTranslations(lang);
    if (formioTranslate) {
      settingsArray.forEach((setting) => {
        setting.i18n = {};
        // need set "en" always, because bug on add datetime component in formio not trigger event https://github.com/formio/formio.js/issues/5228
        setting.i18n["en"] = formioTranslate;
        setting.language = "en";
      });
    }
  } catch (e) {
    if (lang !== "en") {
      try {
        await setFormioI18nSetting("en", settingsArray);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(e);
    }
  }
};
