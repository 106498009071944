import { Components } from "formiojs";
import { facebookButtonTemplate } from "@/formio-templates/facebookButtonTemplate";
const FieldComponent = (Components as any).components.field;

export default class FacebookButton extends (FieldComponent as any) {
  static schema(): any {
    return FieldComponent.schema({
      type: "facebookButton",
      label: "Subscribe with Facebook",
    });
  }

  static builderInfo = {
    title: "Facebook Button",
    group: "basic",
    icon: "fa-brands fa-facebook",
    weight: 70,
    schema: FacebookButton.schema(),
  };

  get labelInfo() {
    return {
      hidden: true,
    };
  }

  createLabel() {
    // No label needed for buttons.
  }

  render() {
    return super.render(facebookButtonTemplate(this));
  }
}
