export const radioTemplate = (ctx: any) => {
  return `<div
  class="form-radio radio"
  ref="radioGroup"
  role="${ctx.component.type === "selectboxes" ? "group" : "radiogroup"}"
  aria-required="${ctx.input.component.validate.required}"
  aria-labelledby="l-${ctx.instance.id}-${ctx.component.key}"
  ${
    ctx.component.description
      ? 'aria-describedby="d-${ctx.instance.id}-${ctx.component.key}"'
      : ""
  }
>
  ${(function () {
    let row = "";
    ctx.values.forEach(function (item: any) {
      row += `<div class="${ctx.input.attr.type} ${
        ctx.component.optionsLabelPosition &&
        ctx.component.optionsLabelPosition !== "right"
          ? "pl-0"
          : ""
      } custom-control custom-${
        ctx.component.type === "selectboxes" ? "checkbox" : "radio"
      } ${ctx.inline ? "custom-control-inline" : ""}" ref="wrapper">
    <label class="form-check-label custom-radio-label label-position-${
      ctx.component.optionsLabelPosition
    }" for="${ctx.instance.root && ctx.instance.root.id}-${ctx.id}-${ctx.row}-${
        item.value
      }">
      ${
        ctx.component.optionsLabelPosition === "left" ||
        ctx.component.optionsLabelPosition === "top"
          ? `<span>${ctx.t(item.label, { _userInput: true })}</span>`
          : ""
      }
      <${ctx.input.type}
        ref="input"
        ${(function () {
          let r = "";
          for (const attr in ctx.input.attr) {
            if (attr === "class") {
              r += `${attr}="${ctx.input.attr[attr]} custom-control-input"`;
              break;
            }
            r += `${attr}="${ctx.input.attr[attr]}"`;
          }
          return r;
        })()}
        value="${item.value}"
        ${
          ctx.value &&
          (ctx.value === item.value ||
            (typeof ctx.value === "object" &&
              ctx.value.hasOwnProperty(item.value) &&
              ctx.value[item.value]))
            ? "checked=true"
            : ""
        }
        ${item.disabled ? "disabled=true" : ""}
        id="${ctx.instance.root && ctx.instance.root.id}-${ctx.id}-${ctx.row}-${
        item.value
      }"
        role="${ctx.component.type === "selectboxes" ? "checkbox" : "radio"}"
      >
      <div class="custom-outer-box"><div class="custom-inner-box"></div></div>
      ${
        !ctx.component.optionsLabelPosition ||
        ctx.component.optionsLabelPosition === "right" ||
        ctx.component.optionsLabelPosition === "bottom"
          ? `<span class="checkbox-text">${ctx.t(item.label, {
              _userInput: true,
            })}</span>`
          : ""
      }
    </label>
  </div>`;
    });
    return row;
  })()}
</div>`;
};
