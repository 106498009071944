export const builderSidebarGroupTemplate = (ctx: any) => {
  return `<div class="panel panel-group card form-builder-panel" ref="group-panel-${
    ctx.groupKey
  }">
      <div class="card-header form-builder-group-header" id="heading-${
        ctx.groupKey
      }">
        <h5 class="mb-0 mt-0">
          <button
            class="btn btn-block builder-group-button"
            type="button"
            data-toggle="collapse"
            data-target="#group-${ctx.groupKey}"
            data-parent="#${ctx.groupId}"
            aria-expanded="${!!ctx.group.default}"
            aria-controls="group-${ctx.groupKey}"
            ref="sidebar-anchor"
          >
            ${ctx.t(ctx.group.title, { _userInput: true })}
          </button>
        </h5>
      </div>
      <div
        id="group-${ctx.groupKey}"
        class="collapse ${ctx.group.default ? " in" : ""}"
        data-parent="#${ctx.groupId}"
        data-default="${!!ctx.group.default}"
        aria-labelledby="heading-${ctx.groupKey}"
        ref="sidebar-group"
      >
        <div id="group-container-${
          ctx.groupKey
        }" class="card-body no-drop p-2" ref="sidebar-container">
          ${(function () {
            let result = "";
            if (ctx.group.componentOrder.length || ctx.subgroups.length) {
              ctx.group.componentOrder.forEach((componentKey: any) => {
                result += `<span
                    data-group="${ctx.groupKey}"
                    data-key="${ctx.group.components[componentKey].key}"
                    data-type="${
                      ctx.group.components[componentKey].schema.type
                    }"
                    class="btn ${(function () {
                      if (ctx.group.components[componentKey].schema.isCustom) {
                        return "btn-warning";
                      }
                      if (ctx.groupKey === "layout") {
                        return "btn-secondary";
                      }
                      return "btn-primary";
                    })()} btn-sm btn-block formcomponent drag-copy"
                  >
                    ${
                      ctx.group.components[componentKey].icon
                        ? `<i class="${ctx.iconClass(
                            ctx.group.components[componentKey].icon
                          )}" style="margin-right: 5px;"></i>`
                        : ""
                    }
                    ${ctx.t(ctx.group.components[componentKey].title, {
                      _userInput: true,
                    })}
                  </span>`;
              });
              result += ctx.subgroups.join("");
              return result;
            } else {
              return `<div>${ctx.t("No Matches Found")}</div>`;
            }
          })()}
        </div>
      </div>
  </div>`;
};
