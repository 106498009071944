export const confirmationTemplate = (ctx: any) => {
  return `<div class="form-check checkbox custom-control custom-checkbox">
  <label class="${
    ctx.inputInfo.labelClass
  } form-check-label custom-radio-label">
    <${ctx.inputInfo.type}
      ref="input"
      ${(function () {
        let r = "";
        for (const attr in ctx.inputInfo.attr) {
          if (attr === "class") {
            r += `${attr}="${ctx.inputInfo.attr[attr]} custom-control-input"`;
            break;
          }
          r += `${attr}="${ctx.inputInfo.attr[attr]}"`;
        }
        return r;
      })()}
      ${ctx.checked ? "checked=true" : ""}
      aria-required="${ctx.component.validate.required}"
      >
    <div class="custom-outer-box"><div class="custom-inner-box"></div></div>
    ${
      !ctx.labelIsHidden()
        ? `<span class="checkbox-text">${ctx.confirmLabel}</span>`
        : ""
    }
    ${ctx.inputInfo.content ? ctx.inputInfo.content : ""}
    </${ctx.inputInfo.type}>
  </label>
  ${
    ctx.component.tooltip
      ? `<i ref="tooltip" tabindex="0" class="${ctx.iconClass(
          "question-sign"
        )} text-muted" data-tooltip="${ctx.component.tooltip}"></i>`
      : ""
  }
  ${
    ctx.component.description
      ? `<div class="form-text text-muted">${ctx.t(ctx.component.description, {
          _userInput: true,
        })}</div>`
      : ""
  }
</div>`;
};
