import { Components } from "formiojs";
import { customTextTemplate } from "@/formio-templates/customTextTemplate";
const FieldComponent = (Components as any).components.field;

export default class CustomTextComponent extends (FieldComponent as any) {
  static schema(): any {
    return FieldComponent.schema({
      type: "customText",
      label: "Text",
      content: "Text",
      input: false,
    });
  }

  static builderInfo = {
    title: "Text",
    group: "layout",
    icon: "terminal",
    weight: 0,
    schema: CustomTextComponent.schema(),
  };

  get labelInfo() {
    return {
      hidden: true,
    };
  }

  createLabel() {
    // No label needed for pudding.
  }

  render() {
    return super.render(customTextTemplate(this));
  }
}
