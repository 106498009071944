export const wizardTemplate = (ctx: any) => {
  return `
<div class="formio-component formio-component-label-hidden">
  <h2 class="formio-component form-headline"></h2>
  <div class="formio-component formio-form">
      <div style="position: relative;">
          ${(function () {
            let r = "";
            if (ctx.wizardHeaderType === "wizardHeaderVertical") {
              r += `<div class="row">`;
              if (ctx.wizardHeaderLocation !== "right") {
                r += `<div class="col-sm-2">${ctx.wizardHeader}</div>`;
              }
              r += `<div class="wizard-page col-sm-10" ref="${ctx.wizardKey}">${ctx.components}</div>`;
              if (ctx.wizardHeaderLocation === "right") {
                r += `<div class="col-sm-2">${ctx.wizardHeader}</div>`;
              }
              r += "</div>";
              r += `<div class="col-sm-offset-2 col-sm-10 ${
                ctx.wizardHeaderLocation === "right"
                  ? "col-sm-offset-0 col-md-offset-0"
                  : ""
              }">
                ${ctx.wizardNav}
              </div>`;
            } else {
              r += `${ctx.wizardHeader}`;
              r += `<div class="wizard-page" ref="${ctx.wizardKey}">${ctx.components}</div>`;
              r += `${ctx.wizardNav}`;
            }
            return r;
          })()}
      </div>
  </div>
</div>`;
};
